<template>
  <div class="login-wrapper">
    <div class="div-header">
      <div class="row row-header">
        <div class="col-6 logo">
          <img
            alt="Panasonic Logo"
            class="nav-logo"
            contain
            src="@/assets/logo-KTC.png"
          />
        </div>
      </div>
    </div>
    <div class="row row-title-page">
      <v-col cols="12">
        <div class="app-title">
          <div class="top"></div>
          <div class="middle">Smart Lighting Solution</div>
        </div>
      </v-col>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center card-login">
          <div class="col-md-8 col-lg-6 col-xl-5 col-card-login">
            <div class="card card-login">
              <div class="card-body shadow p-4">
                <v-form
                  @submit.prevent="submit"
                  autocomplete="new-password"
                  v-model="valid"
                >
                  <v-row no-gutters>
                    <v-col cols="4" class="login-label">New Password</v-col>
                    <v-col cols="8">
                      <v-text-field
                        autocomplete="new-password"
                        label=""
                        class="login-input mt-17"
                        v-model="password"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        :type="showPass ? 'text' : 'password'"
                        :rules="validateNewPass"
                        prepend-inner-icon="fas fa-lock"
                      >
                        <template v-slot:append>
                          <a
                            style="margin-top: 2px"
                            @click="showPass = !showPass"
                            v-html="
                              renderIcon(
                                showPass,
                                'i',
                                'fas fa-eye',
                                'fas fa-eye-slash'
                              )
                            "
                          >
                          </a>
                        </template>
                      </v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errNewPassCount > 0"
                      >
                        {{ newPassValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4" class="login-label">Confirm Password</v-col>
                    <v-col cols="8">
                      <v-text-field
                        autocomplete="new-password"
                        label=""
                        class="login-input"
                        v-model="confirmPass"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        prepend-inner-icon="fas fa-lock"
                        :type="showPass ? 'text' : 'password'"
                        :rules="validateConfirmNewPass"
                      >
                        <template v-slot:append>
                          <a
                            style="margin-top: 2px"
                            @click="showPass = !showPass"
                            v-html="
                              renderIcon(
                                showPass,
                                'i',
                                'fas fa-eye',
                                'fas fa-eye-slash'
                              )
                            "
                          >
                          </a>
                        </template>
                      </v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errConfirmNewPassCount > 0"
                      >
                        {{ confirmNewPassValidationMsg }}
                      </div>
                      <div
                        id="error-text"
                        class="error-message error-message-pink mb-20"
                        v-if="!success && msg !== ''"
                      >
                        {{ msg }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" class="forgot-link text-right">
                      <router-link to="/"> Back to Login </router-link>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12 text-center">
                      <button
                        :disabled="!valid"
                        type="submit"
                        :class="{
                          'no-cursor': !valid,
                        }"
                        class="button-login"
                      >
                        Change Password
                      </button>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-title">
      <span>{{ ktc_version }}</span>
    </div>
    <div class="footer-copyright">
      <h5 style="color: #b5b5b5">
        <span style="color: #ffffff"
          ><span style="color: #ffffff; font-weight: bold">Powered by </span
          >Panasonic</span
        >
      </h5>
    </div>
    <v-dialog
      v-model="messageDialog"
      max-width="600"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>Change Password</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" style="padding-top: 6px">
              <div class="text-center" v-html="message"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-center">
              <button
                class="ml-4 btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style src="../assets/css/login.css"></style>

<style scoped>
.login-label {
  font-size: 20px;
}
</style>

<script>
import { auth } from "../services";
export default {
  data() {
    return {
      ktc_version: "Smart Lighting Solution Ver.2.0.0 ©2022",
      showPass: false,
      showConfirmPass: false,
      errConfirmNewPassCount: 0,
      errNewPassCount: 0,
      newPassValidationMsg: "",
      confirmNewPassValidationMsg: "",
      confirmPass: "",
      password: "",
      msg: "",
      message: "",
      welcomeMsg: "Enter your new password to reset it.",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v?.length >= 8 || "Password must be at least 8 characters",
      ],
      token: "",
      valid: false,
      success: false,
    };
  },

  async created() {
    this.ktc_version = await this.getVersion();
    this.token = this.$router.currentRoute.params.token;
  },
  methods: {
    submit() {
      if (this.password && this.token) {
        const data = {
          new_password: this.password,
          token: this.token,
        };
        return auth
          .resetPassword(data)
          .then((res) => {
            if (res.status == 200 && res.data.success) {
              this.message =
                "Password has been reset successfully. You will be redirect to login page shortly.";
              if (res.data.message) {
                this.message = res.data.message;
              }
              this.messageDialog = true;
              this.success = true;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            this.success = false;
            this.msg = "Your password cannot be reset.";
            if (err?.response?.data?.message) {
              this.msg = err?.response?.data?.message;
            }
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      this.message = "";
      this.$router.push("/login");
    },
  },
  computed: {
    confirmPassRules() {
      return [this.confirmPass === this.password || "Passwords do not match"];
    },
    validateNewPass() {
      return [
        (v) => {
          if (this.errNewPassCount == 0) {
            this.errNewPassCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.newPassValidationMsg = "Password is required";
            return false;
          } else if (v.length < 6) {
            this.newPassValidationMsg =
              "Password must be at least 6 characters";
            this.valid = false;
            return false;
          } else {
            this.newPassValidationMsg = "";
            this.password = v;
            return true;
          }
        },
      ];
    },
    validateConfirmNewPass() {
      return [
        (v) => {
          if (this.errConfirmNewPassCount == 0) {
            this.errConfirmNewPassCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.confirmNewPassValidationMsg = "Password is required";
            return false;
          } else if (v.length < 6) {
            this.confirmNewPassValidationMsg =
              "Password must be at least 6 characters";
            this.valid = false;
            return false;
          } else if (v !== this.password) {
            this.confirmNewPassValidationMsg = "Passwords do not match";
            this.valid = false;
            return false;
          } else {
            this.confirmNewPassValidationMsg = "";
            return true;
          }
        },
      ];
    },
  },
};
</script>
