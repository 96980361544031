var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-wrapper"},[_vm._m(0),_c('div',{staticClass:"row row-title-page"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"app-title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"middle"},[_vm._v("Smart Lighting Solution")])])])],1),_c('div',{staticClass:"account-pages my-5 pt-sm-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-center card-login"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5 col-card-login"},[_c('div',{staticClass:"card card-login"},[_c('div',{staticClass:"card-body shadow p-4"},[_c('v-form',{attrs:{"autocomplete":"new-password"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"login-label",attrs:{"cols":"4"}},[_vm._v("New Password")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"login-input mt-17",attrs:{"autocomplete":"new-password","label":"","min":"8","required":"","outlined":"","filled":"","single-line":"","type":_vm.showPass ? 'text' : 'password',"rules":_vm.validateNewPass,"prepend-inner-icon":"fas fa-lock"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('a',{staticStyle:{"margin-top":"2px"},domProps:{"innerHTML":_vm._s(
                            _vm.renderIcon(
                              _vm.showPass,
                              'i',
                              'fas fa-eye',
                              'fas fa-eye-slash'
                            )
                          )},on:{"click":function($event){_vm.showPass = !_vm.showPass}}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(this.errNewPassCount > 0)?_c('div',{staticClass:"error-message error-message-pink"},[_vm._v(" "+_vm._s(_vm.newPassValidationMsg)+" ")]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"login-label",attrs:{"cols":"4"}},[_vm._v("Confirm Password")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"login-input",attrs:{"autocomplete":"new-password","label":"","min":"8","required":"","outlined":"","filled":"","single-line":"","prepend-inner-icon":"fas fa-lock","type":_vm.showPass ? 'text' : 'password',"rules":_vm.validateConfirmNewPass},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('a',{staticStyle:{"margin-top":"2px"},domProps:{"innerHTML":_vm._s(
                            _vm.renderIcon(
                              _vm.showPass,
                              'i',
                              'fas fa-eye',
                              'fas fa-eye-slash'
                            )
                          )},on:{"click":function($event){_vm.showPass = !_vm.showPass}}})]},proxy:true}]),model:{value:(_vm.confirmPass),callback:function ($$v) {_vm.confirmPass=$$v},expression:"confirmPass"}}),(this.errConfirmNewPassCount > 0)?_c('div',{staticClass:"error-message error-message-pink"},[_vm._v(" "+_vm._s(_vm.confirmNewPassValidationMsg)+" ")]):_vm._e(),(!_vm.success && _vm.msg !== '')?_c('div',{staticClass:"error-message error-message-pink mb-20",attrs:{"id":"error-text"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"forgot-link text-right",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" Back to Login ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12 text-center"}},[_c('button',{staticClass:"button-login",class:{
                        'no-cursor': !_vm.valid,
                      },attrs:{"disabled":!_vm.valid,"type":"submit"}},[_vm._v(" Change Password ")])])],1)],1)],1)])])])])]),_c('div',{staticClass:"footer-title"},[_c('span',[_vm._v(_vm._s(_vm.ktc_version))])]),_vm._m(1),_c('v-dialog',{attrs:{"max-width":"600","persistent":"","content-class":"profile-confirm-dialog"},model:{value:(_vm.messageDialog),callback:function ($$v) {_vm.messageDialog=$$v},expression:"messageDialog"}},[_c('v-card',{attrs:{"id":"profile-confirm"}},[_c('v-card-title',[_vm._v("Change Password")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding-top":"6px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.message)}})]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12 pt-4"}},[_c('button',{staticClass:"ml-4 btn btn-primary btn-custom",attrs:{"ripple":false},on:{"click":_vm.closeMessage}},[_vm._v(" OK ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-header"},[_c('div',{staticClass:"row row-header"},[_c('div',{staticClass:"col-6 logo"},[_c('img',{staticClass:"nav-logo",attrs:{"alt":"Panasonic Logo","contain":"","src":require("@/assets/logo-KTC.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-copyright"},[_c('h5',{staticStyle:{"color":"#b5b5b5"}},[_c('span',{staticStyle:{"color":"#ffffff"}},[_c('span',{staticStyle:{"color":"#ffffff","font-weight":"bold"}},[_vm._v("Powered by ")]),_vm._v("Panasonic")])])])
}]

export { render, staticRenderFns }